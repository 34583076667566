<template>
  <div class="events-container">
    <EventsCalendar
      @dayClick="toEvents()"
      @goPrev="goPrev"
      @goNext="goNext"
      :notSetQuery="true"
      @updFilter="updFilter"
    />
    <div
      class="events-list__title"
      ref="eventsList"
      v-if="notPassedEvents && notPassedEvents.length"
    >
      Ближайшие
    </div>
    <div class="events-list" v-if="notPassedEvents && notPassedEvents.length">
      <Event
        v-for="ind in 3"
        :key="ind"
        :event="notPassedEvents[ind - 1]"
        class="mb-4"
      />
    </div>
    <div
      @click="toAllEvents({ name: 'Events' })"
      class="events-list__button button button_empty-gray"
    >
      Все мероприятия
    </div>
  </div>
</template>

<script>
import EventsCalendar from "@/components/main/events/calendar/EventsCalendar.vue";
import Event from "./Event.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Events",
  components: { EventsCalendar, Event },
  data: () => ({}),
  computed: {
    ...mapGetters(["FilteredEvents"]),
    notPassedEvents() {
      return this.FilteredEvents.filter(el => !el.state)
    }
  },
  methods: {
    ...mapActions(['fetchEvents']),
    async updFilter(filter) {
      await this.fetchEvents(filter);
    },
    toEvents() {
      if (window.innerWidth < 768) {
        this.$refs.eventsList.scrollIntoView({ behavior: "smooth" });
      }
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "main calendar date click", {
          "main calendar date click": {
             ...this.$root.ymFields
          },
        });
      }
    },
    goPrev(month) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "main calendar month fllter use", {
          "main calendar month fllter use": {
            month: month,
             ...this.$root.ymFields
          },
        });
      }
    },
    goNext(month) {
      if (typeof ym !== "undefined") {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "main calendar month fllter use", {
            "main calendar month fllter use": {
              month: month,
               ...this.$root.ymFields
            },
          });
        }
      }
    },
    toAllEvents(to) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "main page events click", {
          "main page events click": {
             ...this.$root.ymFields
          },
        });
      }
      this.$router.push(to).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.events-list {
  &__title {
    margin-top: 32px;
    margin-bottom: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #830051;
  }

  &__button {
    margin: 32px auto 0;
    width: 173px;

    @media screen and (max-width: 1220px) {
      margin: 32px 0 0;
    }
  }
}
</style>